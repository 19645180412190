import React, { useEffect, useState } from "react"
import Navbar from "../navbar"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./style.scss"
import constants from "../../constants/contants"

import { Link } from "gatsby"

const data = [
  {
    heading: "November 2024 Issue",
    hightlights: [
      // "Dawn Of A New Era<br/>The Rise of Commercial Space Exploration. ",
      // "Hidden In Plain Sight<br/>Unseen Struggles:",
      // "Fascinating Forensics",
      // "Ranking The Rankings<br/>University A-Lists",
      // "Choosing The Right Career",
      // "Decoding Income Tax",
      // "The Truth Behind the 10,000-Step Benchmark.",
      // "Career Focus<br/>Unlock AI’s secrets and explore a career in artificial intelligence. Shape the future.",
      // "Sci-Tech<br/>Discover the fascinating world of science and technology. Spark your curiosity with intriguing facts and insights.",
      // "Intelligent Take<br/> An eminent principal decodes multiple intelligences in the Indian context for you, that may change your perspective on intelligence.",
      // "Inventors Club<br/> Meet Charles Goodyear, the pioneer who revolutionized the world with vulcanized rubber. Learn about his groundbreaking discovery.",
      // "CBSE In Quotes<br/> Join the journey where philosophy meets pedagogy. An eminent  principal’s insightful take reveals how CBSE aligns with time-tested principles of learning and growth, against   timeless quotes by all-time great authors and philosophers.",
      // "Captain Shiva<br/>Join Captain Shiva on his thrilling adventures as he saves the world from evil forces. Let’s continue with the action-packed excitement.",
      // "Sporting Spirit<br/>Sailing takes centrestage with the America’s Cup, showcasing human endurance and skill.",
      // "Curiosity<br/>Explore the quirky habits of famous scientists who changed the world.",
      "Check It Out !<br/>The battle between the reigning world champion and the youngest ever challenger in chess history !",
      "Reverse Osmosis<br/>Learn how RO technology works and how it has found its way to our homes.",
      "Towering Above The Rest In Sports !<br/>Get ready to experience the excitement of basketball !",
      "Game Changer !<br/>Unlocking the power of co-scholastic skills : The key to holistic development.",
      "Blue Sky ? What’s It ?<br/>Disrupting school education because of smog – not really an open and shut case !",
      "Yatha Drishti Tatha Srishti<br/>Journey in perception; one of self discovery and cultural integration in education.",
      "Jewel Of The Maghreb<br/>Visit the land of stunning natural beauty, vibrant culture, and rich history.",
    ],

    // coverLink: "https://school-live.com/assets/images/2023/december.jpg",
    coverLink: constants.currentCoverLink,
  },
]

export default function Hero() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const [transformValue, setTransformValue] = useState(0)

  useEffect(() => {
    function handleScroll() {
      let yPos = window.scrollY

      if (timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(function () {
        if (yPos < 100) {
          setTransformValue(yPos)
        }
      }, 0)
    }

    var timer = null
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className="hero">
      <Navbar />

      <img
        className="hero__bg"
        alt=""
        src="http://school-live.com/assets/images/hero-img-bg.png"
      ></img>
      <div className="hero__figures">
        <div
          className="hero__figures__small-el"
          style={{
            transform: `translateX(-${transformValue}px)`,
            transition: "1s",
          }}
        ></div>
        <div
          className="hero__figures__small-el2"
          style={{
            transform: `translateX(-${transformValue}px)`,
            transition: "2s",
          }}
        ></div>
        <div
          className="hero__figures__small-el3"
          style={{
            transform: `translateX(${transformValue}px)`,
            transition: "2.5s",
          }}
        ></div>
      </div>

      <div className="hero__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div key={`hero-slide-${i}`} className="hero__slide">
              <div className="hero__slide__content">
                <div className="hero__slide__text">
                  <h1 className="hero__slide__heading">{item.heading}</h1>
                  <h2>Hightlights</h2>
                  <ul>
                    {item.hightlights.map((item, i) => {
                      return (
                        <li
                          key={`hightlight-item-${i}`}
                          dangerouslySetInnerHTML={{ __html: item }}
                        ></li>
                      )
                    })}
                  </ul>
                  <div className="hero__slide__buttons">
                    <Link className="button-primary" to="/subscriptions">
                      Subscriptions
                    </Link>
                    <Link className="button-primary" to="/archives">
                      Explore Issues
                    </Link>
                    <br />
                    <Link
                      className="button-primary btn-membership"
                      to="/membership"
                    >
                      Elite School READ Club Membership
                    </Link>
                  </div>
                </div>
                <div className="hero__slide__cover">
                  <img alt={`${item.heading} Cover`} src={item.coverLink}></img>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
